import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
const configFirebase = {
  apiKey: "AIzaSyBldvByIlWcv2F1ka0PKQGAgD_gD19bXfQ",
  authDomain: "lotto-landingpage2.firebaseapp.com",
  databaseURL: "https://lotto-landingpage2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lotto-landingpage2",
  storageBucket: "lotto-landingpage2.appspot.com",
  messagingSenderId: "96982310091",
  appId: "1:96982310091:web:5c9081af4e8dc81c784f4e"
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
